<template>
  <div class="index-qrcode">
    <!-- index:qr-code START -->
    <div class="qr-code">
      <div
        class="headline"
        v-html="$t('terminal.index.qrcode.headline')"
      ></div>
      <div
        class="description"
        v-html="$t('terminal.index.qrcode.description')"
      ></div>
      <div class="image">
        <img
          src="@/assets/images/index-scan-qrcode@3x.png"
          :alt="$t('terminal.index.qrcode.headline')"
        />
      </div>
    </div>
    <!-- index:qr-code END -->
  </div>
</template>

<script>
export default {
  name: 'index-qrcode',
  props: {
    step: {
      type: Number,
    },
  },
};
</script>
